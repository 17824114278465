import React from "react";
import { IoMdClose } from "react-icons/io";
import {
  FaGift,
  FaHandsHelping,
  FaRegCopy,
  FaUser,
  FaFlag,
  FaQrcode,
} from "react-icons/fa";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import {
  BiLogOutCircle,
  BiDollarCircle,
  BiChevronLeft,
  BiChevronRight,
  BiChevronDown,
  BiChevronUp,
  BiGitPullRequest,
} from "react-icons/bi";
import {
  AiOutlineUnorderedList,
  AiFillSetting,
  AiOutlineStar,
  AiFillStar,
} from "react-icons/ai";
import {
  MdDashboard,
  MdDelete,
  MdEdit,
  MdOutlineManageAccounts,
  MdAccountBox,
  MdMyLocation,
} from "react-icons/md";
import { FiSave } from "react-icons/fi";
import { FcTimeline } from "react-icons/fc";
import { GrTransaction } from "react-icons/gr";
import { RiUpload2Line } from "react-icons/ri";
import { IoMdMore } from "react-icons/io";
import { FiMoreVertical } from "react-icons/fi";
import { VscServerProcess } from "react-icons/vsc";
import { TbReportAnalytics } from "react-icons/tb";
import { AiOutlineCalculator } from "react-icons/ai";
import { ImUserCheck } from "react-icons/im";
import { TbUserSquare } from "react-icons/tb";
import { MdOutlineReport } from "react-icons/md";
import { LuWallet } from "react-icons/lu";
import { IoInformationCircleOutline } from "react-icons/io5";
import { FaArrowLeft } from "react-icons/fa6";
import { BiSolidUserPin } from "react-icons/bi";
import { IoMdRefresh } from "react-icons/io";
import { FaCheck } from "react-icons/fa";
import { BiPurchaseTag } from "react-icons/bi";
import { IoReorderThree } from "react-icons/io5";
import { TbAffiliate } from "react-icons/tb";
import { MdMapsHomeWork } from "react-icons/md";
import { BsGearWideConnected } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import { RiFileExcel2Fill } from "react-icons/ri";
import { FaStar } from "react-icons/fa";
import { FaInfoCircle } from "react-icons/fa";

export const reactIcons = {
  user: <FaUser />,
  save: <FiSave />,
  logout: <BiLogOutCircle />,
  dollar: <BiDollarCircle />,
  list: <AiOutlineUnorderedList />,
  arrowleft: <BiChevronLeft />,
  arrowright: <BiChevronRight />,
  arrowDown: <BiChevronDown />,
  arrowUp: <BiChevronUp />,
  dashboard: <MdDashboard />,
  setting: <AiFillSetting />,
  edit: <MdEdit />,
  delete: <MdDelete />,
  manage: <MdOutlineManageAccounts />,
  gift: <FaGift />,
  star: <AiOutlineStar />,
  fillstar: <AiFillStar />,
  eyeVisible: <AiFillEye />,
  eyeInVisible: <AiFillEyeInvisible />,
  close: <IoMdClose />,
  copy: <FaRegCopy />,
  handShake: <FaHandsHelping />,
  timeline: <FcTimeline />,
  transactions: <GrTransaction />,
  request: <BiGitPullRequest />,
  flag: <FaFlag />,
  qrCode: <FaQrcode />,
  account: <MdAccountBox />,
  upload: <RiUpload2Line />,
  more: <FiMoreVertical />,
  moreItems: <IoMdMore />,
  location: <MdMyLocation />,
  process: <VscServerProcess />,
  TbReportAnalytics: <TbReportAnalytics />,
  calculater: <AiOutlineCalculator />,
  userCheck: <ImUserCheck />,
  kyc: <TbUserSquare />,
  report: <MdOutlineReport />,
  wallet: <LuWallet />,
  info: <IoInformationCircleOutline />,
  leftArrow: <FaArrowLeft />,
  login: <BiSolidUserPin />,
  gear: <BsGearWideConnected />,
  refresh: <IoMdRefresh />,
  check: <FaCheck />,
  purchase: <BiPurchaseTag />,
  toggle: <IoReorderThree />,
  affiliate: <TbAffiliate />,
  franchise: <MdMapsHomeWork />,
  search: <FiSearch />,
  excell: <RiFileExcel2Fill />,
  starFill: <FaStar />,
  infoFill: <FaInfoCircle />,
};
