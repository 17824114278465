import React from 'react';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
const TotalEarnedCoinModal = ({ open1, setOpen1, coinData }) => {
  return (
    <Dialog
      open={open1}
      onClose={() => setOpen1(false)}
      scroll={'paper'}
      fullWidth={true}
      maxWidth={'sm'}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogContent dividers={true}>
        <div>
          <div className="max-w-4xl">
            <div className="flex items-center justify-between gap-2 mb-3">
              <h6>Per Block Reward :</h6>
              <span>{coinData?.initialBlockReward || 0}</span>
            </div>
            <div className="flex items-center justify-between gap-2 mb-3">
              <h6>Per block reward after halving :</h6>
              <span>{coinData?.perBlockRewardAfterHalving || 0}</span>
            </div>
            <div className="flex items-center justify-between gap-2 mb-3">
              <h6>Per fraction share :</h6>
              <span>{coinData?.perFractionShare || 0}</span>
            </div>
            <div className="flex items-center justify-between gap-2 mb-3">
              <h6>Per block earning :</h6>
              <span>{coinData?.perBlockEarning || 0}</span>
            </div>
            <p className="mt-10">
              <strong>Note</strong> : In every two year block reward get halve,
              so while calculating the earning consider this constant carefully
            </p>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <button
          onClick={() => {
            setOpen1(false);
          }}
          type="button"
          className="btn btn-danger min-w-[130px] md:min-w-[200px] bg-[#EE0000]"
        >
          Close
        </button>
      </DialogActions>
    </Dialog>
  );
};
export default TotalEarnedCoinModal;
