import React from "react";
import { footerLinks } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
// import { Link } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full z-[9999] bottom-0 left-0 p-2 mt-5">
      <ul className="flex justify-end items-center gap-5">
        {footerLinks.map((item, index) => (
          <li key={index}>
            <button
              onClick={() => navigate(item.link)}
              className="text-[11px] text-white"
            >
              {item.title}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Footer;
