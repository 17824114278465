import React from 'react';
import { Dialog, DialogActions, DialogContent } from '@mui/material';

const headings = ['Node Level', 'No. of Fraction'];

const ReportNodesModal = ({ setOpen, open, reportValidationData }) => {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      scroll={'paper'}
      fullWidth={true}
      maxWidth={'sm'}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogContent dividers={true}>
        <div className="overflow-x-auto custom-scroll opacity-[100%] duration-1000">
          <table className="text-xs leading-6 font-normal w-full">
            <thead>
              <tr className="bg-gradient-red rounded-lg text-white">
                {headings.map((item, index) => {
                  return (
                    <th key={item} className="group">
                      <div
                        className={`py-1 my-1 flex items-center justify-center text-left font-semibold ${
                          index === headings.length - 1
                            ? 'border-r-0'
                            : 'border-white border-r'
                        }`}
                      >
                        {item}
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {reportValidationData?.length > 0 ? (
                reportValidationData &&
                reportValidationData?.map((item, index) => {
                  return (
                    <tr
                      className=" bg-[#181B1D] h-[42px] border-2 border-[#393939] text-white mb-2"
                      key={index}
                    >
                      <td>
                        <div className="text-center border-r border-white">
                          {item?.nodeId}
                        </div>
                      </td>
                      <td>
                        <div className="text-center">{item?.fractionsSold}</div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={7} className="text-white text-center py-4">
                    Data Not Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </DialogContent>
      <DialogActions>
        <button
          onClick={() => {
            setOpen(false);
          }}
          type="button"
          className="btn btn-danger min-w-[130px] md:min-w-[200px] bg-[#EE0000]"
        >
          Close
        </button>
      </DialogActions>
    </Dialog>
  );
};
export default ReportNodesModal;
