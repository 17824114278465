import React from "react";
import { useSelector } from "react-redux";

const UserProfile = () => {
  const { userData } = useSelector((state) => state?.user || {});
  return (
    <div className="">
      <div className="flex items-center justify-between mb-4 md:mb-10">
        <h4 className="cursor-pointer text-white text-start md:text-center text-16 md:text-[24px] lg:text-[24px]">
          Profile
        </h4>
      </div>

      <div className="flex justify-center items-start lg:items-center h-[100%] mt-8">
        <div className="w-full max-w-[670px] mx-auto relative z-[9999]">
          <form className="">
            <div className="w-full gap-6 mt-4 ">
              <div className="text-white px-3">
                <div className="flex items-center gap-2">
                  <div className="input_field mt-3 md:mt-2 lg:mt-3 w-1/2">
                    <label className="mb-2 block text-10 md:text-14">
                      First Name
                    </label>
                    <input
                      type="text"
                      placeholder="First Name"
                      value={userData && userData?.firstname}
                      className={"bg-[#0F0F0F] border text-14"}
                      disabled
                    />
                  </div>
                  <div className="input_field mt-3 md:mt-2 lg:mt-3 w-1/2">
                    <label className="mb-2 block text-10 md:text-14">
                      Last Name
                    </label>
                    <input
                      type="text"
                      placeholder="Last Name"
                      value={userData && userData?.lastname}
                      className={"bg-[#0F0F0F] border text-14"}
                      disabled
                    />
                  </div>
                </div>
                <div className="input_field mt-3 md:mt-2 lg:mt-3">
                  <label className="mb-2 block text-10 md:text-14">Email</label>
                  <input
                    type="text"
                    placeholder="Email"
                    value={userData && userData?.email}
                    className={"bg-[#0F0F0F] border text-14"}
                    disabled
                  />
                </div>
                <div className="input_field mt-3 md:mt-2 lg:mt-3">
                  <label className="mb-2 block text-10 md:text-14">City</label>
                  <input
                    type="text"
                    placeholder="City"
                    value={userData && userData?.meta?.city}
                    className={"bg-[#0F0F0F] border text-14"}
                    disabled
                  />
                </div>
                <div className="input_field mt-3 md:mt-2 lg:mt-3">
                  <label className="mb-2 block text-10 md:text-14">State</label>
                  <input
                    type="text"
                    placeholder="State"
                    value={userData && userData?.meta?.state}
                    className={"bg-[#0F0F0F] border text-14"}
                    disabled
                  />
                </div>
                <div className="input_field mt-3 md:mt-2 lg:mt-3">
                  <label className="mb-2 block text-10 md:text-14">
                    Country
                  </label>
                  <input
                    type="text"
                    placeholder="Country"
                    value={userData && userData?.country}
                    className={"bg-[#0F0F0F] border text-14"}
                    disabled
                  />
                </div>
                <div className="input_field mt-3 md:mt-2 lg:mt-3">
                  <label className="mb-2 block text-10 md:text-14">
                    Address 1
                  </label>
                  <input
                    type="text"
                    placeholder="Address 1"
                    value={userData && userData?.meta?.address_line_1}
                    className={"bg-[#0F0F0F] border text-14"}
                    disabled
                  />
                </div>
                <div className="input_field mt-3 md:mt-2 lg:mt-3">
                  <label className="mb-2 block text-10 md:text-14">
                    Address 2
                  </label>
                  <input
                    type="text"
                    placeholder="Address 2"
                    value={userData && userData?.meta?.address_line_2}
                    className={"bg-[#0F0F0F] border text-14"}
                    disabled
                  />
                </div>
                <div className="input_field mt-3 md:mt-2 lg:mt-3">
                  <label className="mb-2 block text-10 md:text-14">
                    Wallet Address
                  </label>
                  <input
                    type="text"
                    placeholder="Wallet Address is not Available"
                    value={(userData && userData?.meta?.walletAddress) || ""}
                    className={"bg-[#0F0F0F] border text-14"}
                    disabled
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default UserProfile;
